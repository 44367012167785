import React, { useState, useMemo, useEffect } from "react";
import { Grid, Mask, Toast, NavBar } from "antd-mobile";
import { useNavigate } from "react-router";
import Footer from "../../components/ManagerFooter";
import { getOneDayStart, getOneDayEnd, getDay, getOneMonthStart, getOneMonthEnd, getMonth } from "../../utils";
import { getShopStatistics } from "../../axios";
import DatePicker from "../../components/DatePicker";
import "./Statistics.css";
import { DownOutline, UpOutline } from "antd-mobile-icons";
import { updateStatisticsTime } from "../../store/shopSlice";
import { useDispatch, useSelector } from "react-redux";
import { PlayType } from "../../constant/betPlayType";

interface StatisticsItem {
    amount: number,
    count: number,
}

interface StatisticsInt {
    bonus: StatisticsItem,
    order: StatisticsItem,
    add: StatisticsItem,
    sub: StatisticsItem,
    win: StatisticsItem,
    ticket: StatisticsItem,
    waitForResult: StatisticsItem,
    followBuy: StatisticsItem,
    followBuyCommission: StatisticsItem,
    charge: StatisticsItem,
    registerUserNum: number,
    orderUserNum: number
}


export default function Statistics() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const statisticsTime = useSelector((state) => state.shop.statistics);
    const [statistics, setStatistics] = useState<StatisticsInt>();
    const [datePickerVisible, setDatePickerVisible] = useState(false);

    const showTimeStr = useMemo(() => {
        if(statisticsTime.precision === "day") {
            return getDay(statisticsTime.startTime);
        }
        if(statisticsTime.precision === "month") {
            return getMonth(statisticsTime.startTime);
        }
        return ""
     }, [statisticsTime])

    useEffect(() => {
        getShopStatistics({
            startTime: statisticsTime.startTime,
            endTime: statisticsTime.endTime,
        }).then(({data}) => {
            if(data.status === "error") {
                Toast.show(data.errorMessage);
            } else {
                setStatistics(data);
            }
        })
    }, [statisticsTime])
    return (
        <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
            <NavBar
                back={null} 
                right={
                <div onClick={() => setDatePickerVisible(true)} style={{
                    background: "#f2f4f7",
                    color: "black",
                    padding: "5px",
                    borderRadius: "12px"
                }}>
                    {showTimeStr}
                    {
                        datePickerVisible ? <UpOutline style={{ marginLeft: "5px" }}/> : <DownOutline style={{ marginLeft: "5px" }}/>
                    }
                    
                </div>
            }>
                数据统计
            </NavBar>
            <div className="statistics-container" style={{ flex: "1 1", overflowY: "auto" }}>
                <div className="statistics-box">                    
                    <Grid columns={2} gap={14}>
                        <Grid.Item span={1}>
                            <div className="half-top column space-between bg bg-1" onClick={() => navigate("/manager/statistics/action", { state: { action: "ticket", startTime: statisticsTime.startTime, endTime: statisticsTime.endTime } })}>
                                <div className="statistics-value">{statistics?.ticket.count || 0} 单</div>
                                <div className="statistics-value">{statistics?.ticket?.amount?.toFixed(2) || 0}</div>
                                <div className="statistics-name">总出票金额</div>
                            </div>
                        </Grid.Item>
                        <Grid.Item span={1}>
                            <div className="half-top column space-between bg bg-2" onClick={() => navigate("/manager/statistics/action", { state: { action: "bonus", startTime: statisticsTime.startTime, endTime: statisticsTime.endTime } })}>
                                <div className="statistics-value">{statistics?.bonus.count || 0} 单</div>
                                <div className="statistics-value">{statistics?.bonus?.amount.toFixed(2) || 0}</div>
                                <div className="statistics-name">派奖总金额</div>
                            </div>
                        </Grid.Item>
                    </Grid>
                </div>
                <div className="statistics-box" style={{marginTop: "12px"}}>
                    <Grid columns={2}>
                        <Grid.Item span={2}>
                            <div className="row-top column space-between bg bg-3"  onClick={() => navigate("/manager/statistics/action", { state: { action: "charge", startTime: statisticsTime.startTime, endTime: statisticsTime.endTime } })}>
                                <div className="statistics-value">{statistics?.charge?.amount.toFixed(2) || 0}</div>
                                <div className="statistics-name">用户充值 手续费约为{((statistics?.charge?.amount || 0) * 0.006).toFixed(2)}元</div>
                            </div>
                        </Grid.Item>
                    </Grid>
                    <Grid columns={2} style={{paddingTop: "12px"}} gap={14}>
                        <Grid.Item span={1}>
                            <div className="half-right-center column space-between bg bg-4" onClick={() => navigate("/manager/statistics/action", { state: { action: "win", startTime: statisticsTime.startTime, endTime: statisticsTime.endTime } })}>
                                <div className="statistics-value">{statistics?.win.count || 0} 单</div>
                                <div className="statistics-value">{statistics?.win.amount?.toFixed(2) || 0}</div>
                                <div className="statistics-name">总获奖金额</div>
                            </div>
                        </Grid.Item>
                        <Grid.Item span={1}>
                            <div className="half-right-center column space-between bg bg-5" onClick={() => navigate("/manager/statistics/bet", { state: { status: "2", startTime: statisticsTime.startTime, endTime: statisticsTime.endTime } })}>
                                <div className="statistics-value">{statistics?.waitForResult.count || 0} 单</div>
                                <div className="statistics-value">{statistics?.waitForResult?.amount.toFixed(2) || 0}</div>
                                <div className="statistics-name">待开奖</div>
                            </div>
                        </Grid.Item>
                        <Grid.Item span={1}>
                            <div className="half-right-center column space-between bg bg-6" onClick={() => navigate("/manager/statistics/action", { state: { action: "add", startTime: statisticsTime.startTime, endTime: statisticsTime.endTime } })}>
                                <div className="statistics-value">{statistics?.add.count || 0} 次</div>
                                <div className="statistics-value">{statistics?.add.amount.toFixed(2) || 0}</div>
                                <div className="statistics-name">手工加款</div>
                            </div>
                        </Grid.Item>
                        <Grid.Item span={1}>
                            <div className="half-right-center column space-between bg bg-7" onClick={() => navigate("/manager/statistics/action", { state: { action: "sub", startTime: statisticsTime.startTime, endTime: statisticsTime.endTime } })}>
                                <div className="statistics-value">{statistics?.sub.count || 0} 次</div>
                                <div className="statistics-value">{statistics?.sub.amount.toFixed(2) || 0}</div>
                                <div className="statistics-name">手工扣款</div>
                            </div>
                        </Grid.Item>
                        <Grid.Item span={1}>
                            <div className="half-right-center column space-between bg bg-10" onClick={() => navigate("/manager/statistics/bet", { state: { playType: PlayType.FollowBuy, startTime: statisticsTime.startTime, endTime: statisticsTime.endTime } })}>
                                <div className="statistics-value">{statistics?.followBuy.count || 0} 次</div>
                                <div className="statistics-value">{statistics?.followBuy.amount.toFixed(2) || 0}</div>
                                <div className="statistics-name">跟单金额</div>
                            </div>
                        </Grid.Item>
                        <Grid.Item span={1}>
                            <div className="half-right-center column space-between bg bg-11" onClick={() => navigate("/manager/statistics/bet", { state: { playType: PlayType.FollowBuy, startTime: statisticsTime.startTime, endTime: statisticsTime.endTime } })}>
                                <div className="statistics-value">{statistics?.followBuyCommission.count || 0} 次</div>
                                <div className="statistics-value">{statistics?.followBuyCommission.amount.toFixed(2) || 0}</div>
                                <div className="statistics-name">跟单代扣金额</div>
                            </div>
                        </Grid.Item>
                    </Grid>
                </div>
                <div className="statistics-box" style={{marginTop: "12px"}}>
                    <div style={{paddingBottom: "10px"}}>{showTimeStr}用户统计</div>
                    <Grid columns={2} gap={14}>
                        <Grid.Item span={1}>
                            <div className="half-top column flex-end bg bg-8" style={{ color: "black" }} onClick={() => navigate("/manager/statistics/register", { state: { showTimeStr, startTime: statisticsTime.startTime, endTime: statisticsTime.endTime } })}>
                                <div className="statistics-value">{statistics?.registerUserNum}</div>
                                <div className="statistics-name">注册用户数</div>
                            </div>
                        </Grid.Item>
                        <Grid.Item span={1}>
                            <div className="half-top column flex-end bg bg-9" style={{ color: "black" }} onClick={() => navigate("/manager/statistics/user/order", { state: { showTimeStr, startTime: statisticsTime.startTime, endTime: statisticsTime.endTime } })}>
                                <div className="statistics-value">{statistics?.orderUserNum}</div>
                                <div className="statistics-name">购彩用户数</div>
                            </div>
                        </Grid.Item>
                    </Grid>
                </div>
                {/* <div className="statistics-box" style={{marginTop: "12px"}}>
                    <div style={{paddingBottom: "10px"}}>账单总计</div>
                    <Grid columns={3} gap={8} style={{marginTop: "10px"}}>
                        <Grid.Item>
                            <AutoCenter>
                                <Space 
                                    direction="vertical" 
                                    align="center" 
                                    onClick={() => navigate("/manager/statistics/shop/balance")}>
                                    <div className="statistics-value">{yesterdayBalance.shopBalance.toFixed(2)}</div>
                                    <div className="statistics-name">昨日店铺余额</div>
                                </Space>
                            </AutoCenter>
                        </Grid.Item>
                        <Grid.Item>
                            <AutoCenter>
                                <Space direction="vertical" align="center" onClick={() => navigate("/manager/statistics/shop/balance")}>
                                    <div className="statistics-value">{todayBalance.shopBalance.toFixed(2)}</div>
                                    <div className="statistics-name">今日店铺余额</div>
                                </Space>
                            </AutoCenter>
                        </Grid.Item>
                        <Grid.Item>
                                <AutoCenter>
                                    <Space direction="vertical" align="center" onClick={() => navigate("/manager/statistics/shop/balance")}>
                                    <div className="statistics-value">{(todayBalance.shopBalance - yesterdayBalance.shopBalance).toFixed(2)}</div>
                                    <div className="statistics-name">店铺余额变动</div>
                                    </Space>
                                </AutoCenter>
                        </Grid.Item>
                        <Grid.Item>
                                <AutoCenter>
                                    <Space direction="vertical" align="center" onClick={() => navigate("/manager/statistics/user/balance")}>
                                    <div className="statistics-value">{yesterdayBalance.userBalance?.toFixed(2) || 0}</div>
                                    <div className="statistics-name">昨日托管余额</div>
                                    </Space>
                                </AutoCenter>
                        </Grid.Item>
                        <Grid.Item>
                                <AutoCenter>
                                    <Space direction="vertical" align="center" onClick={() => navigate("/manager/statistics/user/balance")}>
                                    <div className="statistics-value">{todayBalance.userBalance?.toFixed(2) || 0}</div>
                                    <div className="statistics-name">今日托管余额</div>
                                    </Space>
                                </AutoCenter>
                        </Grid.Item>
                        <Grid.Item>
                            <AutoCenter>
                                <Space direction="vertical" align="center" onClick={() => navigate("/manager/statistics/user/balance")}>
                                    <div className="statistics-value">{(todayBalance.userBalance - yesterdayBalance.userBalance).toFixed(2)}</div>
                                    <div className="statistics-name">托管余额变动</div>
                                </Space>
                            </AutoCenter>
                        </Grid.Item>
                    </Grid>
                </div> */}
            </div>
            <Footer />
            <Mask visible={datePickerVisible} onMaskClick={() => setDatePickerVisible(false)}>
                <div>
                    <DatePicker enablePrecision={true} defaultTime={statisticsTime.startTime} onChange={(time, precision) => {
                        setDatePickerVisible(false);
                        let _startTime, _endTime;
                        if(precision === "day") {
                            _startTime = getOneDayStart(time);
                            _endTime =getOneDayEnd(time);
                        }
                        if(precision === "month") {
                            _startTime =getOneMonthStart(time);
                            _endTime = getOneMonthEnd(time);
                        }
                        dispatch(updateStatisticsTime({
                            startTime: _startTime,
                            endTime: _endTime,
                            precision
                        }))
                    }} />
                </div>
            </Mask>
        </div>
    )
}