import { get, post, postForm } from "./base.js";

/**
 * @param {any} data
 */
export function postShopRegister(data) {
    return post("/manager/shop/register", data)
}

/**
 * @param {any} data
 */
export function postRecallOrder(data) {
    return post("/manager/bet/recall", data)
}

/**
 * @param {any} data
 */
export function postHardRecallOrder(data) {
    return post("/manager/bet/hardrecall", data)
}

/**
 * @param {any} params
 */
export function getManagerBetDetail(params) {
    return get("/manager/bet/detail", params)
}

/**
 * @param {any} params
 */
export function getShopStatistics(params) {
    return get("/manager/statistics/v2", params)
}

/**
 * @param {any} params
 */
export function getShopStatisticsBalance(params) {
    return get("/manager/statistics/balance", params);
};

/**
 * @param {any} params
 */
export function getShopStatisticsUserOrder(params) {
    return get("/manager/statistics/user/order", params);
};

/**
 * @param {any} params
 */
export function getManagerStatisticsUserBalance(params) {
    return get("/manager/statistics/user/balance", params);
};

/**
 * @param {any} params
 */
export function getManagerStatisticsShopBalance(params) {
    return get("/manager/statistics/shop/balance", params);
};

/**
 * @param {any} params
 */
export function getManagerDrawInfo(params) {
    return get("/manager/draw/info", params);
};

/**
 * @param {any} body
 */
export function postManagerDrawInfo(body) {
    return post("/manager/draw/info", body);
};

/**
 * @param {any} params
 */
export function getManagerDrawList(params) {
    return get("/manager/draw/list", params);
};

/**
 * @param {any} data
 */
export function postManagerDrawFinish(data) {
    return post("/manager/draw/finish", data);
}

/**
 * @param {any} params
 */
export function getShopAction(params) {
    return get("/manager/shop/action", params);
}

/**
 * @param {any} params
 */
export function getShopStatisticsAction(params) {
    return get("/manager/statistics/action", params)
}

/**
 * @param {any} params
 */
export function getShopStatisticsBet(params) {
    return get("/manager/statistics/bet", params)
}

/**
 * @param {any} params
 */
export function getManagerBetList(params) {
    return get("/manager/bet/list", params)
}

/**
 * @param {any} params
 */
export function getActionList(params) {
    return get("/manager/user/action/list", params)
}

/**
 * @param {any} data
 */
export function postManagerUploadTicketsBet(data) {
    return post("/manager/tickets/upload/bet", data)
}

/**
 * @param {any} data
 */
export function postManagerUploadTicketsImage(data) {
    return postForm("/manager/tickets/upload/img", data)
}

/**
 * @param {any} data
 */
export function postTakeBet(data) {
    return post("/manager/bet/take", data)
}

/**
 * @param {any} data
 */
export function postPayBonus(data) {
    return post("/manager/bonus/pay", data)
}

/**
 * @param {any} data
 */
export function postUpdateBonus(data) {
    return post("/manager/bonus/update", data)
}

/**
 * @param {any} data
 */
export function changeUserBalance(data) {
    return post("/manager/user/balance/change", data)
}

/**
 * @param {any} data
 */
export function changeUserAgentStatus(data) {
    return post("/manager/agent/status", data)
}

/**
 * @param {any} data
 */
export function changeUserStar(data) {
    return post("/manager/user/star", data)
}

/**
 * @param {any} data
 */
export function changeUserStatus(data) {
    return post("/manager/user/status", data)
}

/**
 * @param {any} data
 */
export function changeUserTransfer(data) {
    return post("/manager/user/transfer", data)
}

/**
 * @param {any} data
 */
export function postEditUserRemark(data) {
    return post("/manager/user/remark", data);
}

/**
 * @param {any} params
 */
export function getManagerUserList(params) {
    return get("/manager/user/list", params)
}

/**
 * @param {any} params
 */
export function getManagerUserInfo(params) {
    return get("/manager/user/detail", params)
}

/**
 * @param {any} params
 */
export function getManagerAgentSum(params) {
    return get("/manager/agent/sum", params)
}

/**
 * @param {any} params
 */
export function getManagerAgentList(params) {
    return get("/manager/agent/list", params)
}

/**
 * @param {any} params
 */
export function getManagerAgentUserList(params) {
    return get("/manager/agent/userlist", params)
}

/**
 * @param {any} params
 */
export function getManagerAgentUsers(params) {
    return get("/manager/agent/users", params)
}

/**
 * @param {any} data
 */
export function postManagerAgentUserTransfer(data) {
    return post("/manager/agent/usertransfer", data);
}

/**
 * @param {any} data
 */
export function postManagerAgentUpdateStatus(data) {
    return post("/manager/agent/updatestatus", data);
}

/**
 * @param {any} params
 */
export function getManagerShopUndo(params) {
    return get("/manager/shop/undo", params);
};

/**
 * @param {any} params
 */
export function getManagerShopBasicInfo(params) {
    return get("/manager/shop/basicinfo", params);
};

/**
 * @param {any} params
 */
export function postManagerShopBasicInfo(params) {
    return post("/manager/shop/basicinfo", params);
};

/**
 * @param {any} params
 */
export function postManagerShopDelete(params) {
    return post("/manager/shop/deleteinfo", params);
};

/**
 * @param {any} data
 */
export function postShopConfig(data) {
    return post("/manager/shop/config", data);
}

/**
 * @param {any} data
 */
export function postShopOwnerInfo(data) {
    return post("/manager/shop/ownerinfo", data);
}

/**
 * @param {any} params
 */
export function getShopPayPasswordExist(params) {
    return get("/manager/shop/paypassword/exist", params);
}

/**
 * @param {any} data
 */
export function postShopPayPasswordChange(data) {
    return post("/manager/shop/paypassword/change", data);
}

/**
 * @param {any} params
 */
export function getShopEmployeeList(params) {
    return get("/manager/employee/list", params);
}

/**
 * @param {any} data
 */
export function postShopEmployeeAdd(data) {
    return post("/manager/employee/add", data);
}

/**
 * @param {any} data
 */
export function postShopEmployeeDelete(data) {
    return post("/manager/employee/delete", data);
}

/**
 * @param {any} data
 */
export function postManagerPasswordChange(data) {
    return post("/manager/password/change", data);
}

/**
 * @param {any} params
 */
export function getManagerNotification(params) {
    return get("/manager/notification", params);
}

/**
 * @param {any} params
 */
export function getManagerAlipayURL(params) {
    return get("/manager/alipay/payurl", params);
}

/**
 * @param {any} params
 */
export function getManagerChargeInfo(params) {
    return get("/manager/charge/info", params);
}

/**
 * @param {any} params
 */
export function postManagerChargeUpdate(params) {
    return post("/manager/charge/update", params);
}

/**
 * @param {any} data
 */
export function postManagerUpdateQrCode(data) {
    return postForm("/manager/charge/upload/qrcode", data)
}

/**
 * @param {any} params
 */
export function getPhoneCaptcha(params) {
    return get("/manager/phone/captcha", params)
}

/**
 * @param {any} params
 */
export function getChatUserList(params) {
    return get("/manager/chat/user", params)
}

/**
 * @param {any} params
 */
export function postManagerChatStatus(params) {
    return post("/manager/chat/status", params);
}

/**
 * @param {any} params
 */
export function postManagerChatAudit(params) {
    return post("/manager/chat/audit", params);
}

/**
 * @param {any} params
 */
export function getSTSToken(params) {
    return get("/manager/sts", params)
}

/**
 * @param {any} params
 */
export function getVersion(params) {
    return get("/setting.json", params)
}

/**
 * @param {any} params
 */
export function getNotification(params) {
    return get("/notification.json", params)
}

