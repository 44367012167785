import React, { useEffect, useState, useMemo, useRef } from "react";
import { InfiniteScroll, List, NavBar, Tabs, Toast } from "antd-mobile";
import { useLocation, useNavigate } from "react-router-dom";
import { getManagerBetList } from "../../axios/index.js";
import { cnNameMap, status2CN } from "../../constant/cnNameMap.ts";
import "./BetListNew.css";
import { getTimeInMonth } from "../../utils/index.ts";
import { PlayType } from "../../constant/betPlayType.ts";


export default function BetList() {
    const location = useLocation();
    const navigate = useNavigate();
    const betListRef = useRef(null);
    const [scrollTop, setScrollTop] = useState(0);
    const defaultActiveKey = useMemo(() => {
        return location.state?.tab
    }, [location.state?.tab])
    const [hasMore, setHasMore] = useState(false);
    const pagenation = useRef({ pageNum: 0, loading: false, toBetDetail: false });
    const [currentTab, setCurrentTab] = useState(defaultActiveKey);
    const [betList, setBetList] = useState<any>([]);

    const userId = useMemo(() => {
        return location.state?.id;
    }, [location.state?.id])

    async function loadMore() {
        if(pagenation.current.loading) {
            return;
        }
        pagenation.current.loading = true;
        let query: any = {
            status: currentTab,
            pageNum: pagenation.current.pageNum,
            pageSize: 10,
        };
        pagenation.current.pageNum++;
        if (userId) {
            query["userId"] = userId;
        }
        try {
            const { data } = await getManagerBetList(query);
            setBetList([...betList, ...data.betList]);
            setHasMore(data.betList.length === 10);
        } catch (e) {
            Toast.show("获取彩票失败");
        }
        pagenation.current.loading = false;
    }
    
    useEffect(() => {
        pagenation.current.pageNum = 0;
        setBetList([]);
        setHasMore(true);
    }, [currentTab]);
    const back = () => {
        pagenation.current.toBetDetail = false;
        navigate(-1);
    }
    async function handleClick(betId: string) {
        pagenation.current.toBetDetail = true;
        setScrollTop(betListRef.current?.scrollTop || 0);
        navigate(`/manager/bet/detail/${betId}`);
    }

    useEffect(() => {
        if(location.pathname === "/manager/user/betlist") {
            if(!pagenation.current.toBetDetail) {
                setBetList([]);
                setHasMore(true);
                pagenation.current.pageNum = 0;
                setCurrentTab("0,1,2,3,4,5,6");
            }
            pagenation.current.toBetDetail = false;
            if(betListRef && betListRef.current) {
                setTimeout(() => {
                    betListRef.current.scrollTop = scrollTop
                }, 1)
            }
        }
    }, [location.pathname, scrollTop]);
    return (
        <div className="bet-list">
            <NavBar onBack={back}>
                投注记录
            </NavBar>
            <Tabs onChange={(val) => setCurrentTab(val)} activeKey={currentTab}>
                <Tabs.Tab title='全部' key='0,1,2,3,4,5,6' />
                <Tabs.Tab title='待出票' key='0,1' />
                <Tabs.Tab title='待开奖' key='2' />
                <Tabs.Tab title='已开奖' key='3,4,5' />
                <Tabs.Tab title='已中奖' key='4,5' />
            </Tabs>
            <div className="content"  ref={betListRef}>
                <List>
                    {betList && betList.map((bet: any, index: number) => {
                        const {playType} = bet;
                        return (
                            <List.Item key={index}>
                                <div className="centerBox" onClick={() => handleClick(bet._id)}>
                                    <div className="left">
                                        <div className="t1">
                                            <span style={{ fontWeight: 600 }}>{ cnNameMap[bet.type] }</span>
                                            <span style={{ fontSize: "12px", color: "rgb(153, 153, 153)" }}>
                                                {
                                                    playType === PlayType.GroupBuy && "(合买)"
                                                }
                                                {
                                                    playType === PlayType.PublishFollowBuy && "(发单)"
                                                }
                                                {
                                                    playType === PlayType.FollowBuy && "(跟单)"
                                                }
                                                {
                                                    playType === PlayType.Normal && "(自购)"
                                                }
                                                {
                                                    playType === PlayType.ChaseNum && "(追号)"
                                                }
                                                {
                                                    playType === PlayType.BonusOptimization && "(奖金优化)"
                                                }
                                            </span>
                                        </div>
                                        <div className="t2">{cnNameMap[bet.subType]}</div>
                                    </div>
                                    <div className="center">
                                        <div className="t1" style={{ color: "red" }}>{ bet.spend }元</div>
                                    </div>
                                    <div className="right">
                                        {
                                            ["4", "5"].includes(bet.status) && (
                                                <div className="t1" style={{ fontWeight: 900 }}>
                                                    <span>中奖{bet.bonus?.toFixed(2)}元</span>
                                                </div>
                                            )
                                        }
                                        {
                                            !["4", "5"].includes(bet.status) && (
                                                <div className="t3" style={{ fontWeight: 900 }}>
                                                    <span>{status2CN[bet.status]}</span>
                                                </div>
                                            )
                                        }
                                        
                                        
                                        <div style={{ fontSize: "12px", color: "rgb(153, 153, 153)", marginTop: "5px" }}>{ getTimeInMonth(bet.userBuyTime) }</div>
                                    </div>
                                </div>
                            </List.Item>
                        )
                    })}
                </List>
                <InfiniteScroll loadMore={loadMore} hasMore={hasMore} />
            </div>
            {/* <BList hasMore={hasMore} betList={betList} loadMore={loadMore} onClick={handleClick}/> */}
        </div>
    )
}