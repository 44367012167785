import { Avatar, InfiniteScroll, NavBar, SafeArea, SearchBar, Tabs, Toast } from "antd-mobile";
import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { getManagerUserList } from "../../axios/index.js";
import Footer from "../../components/ManagerFooter.tsx";
import { getTime } from "../../utils/index.ts";
import { SearchOutline } from "antd-mobile-icons";
import defaultUser from "../../assets/default_avatar.png";

export default function UserManage() {
    const navigate = useNavigate();
    const location = useLocation();
    const userListRef = useRef(null)
    const [userList, setUserList] = useState<any[]>([]);
    const [scrollTop, setScrollTop] = useState(0);
    const [phone, setPhone] = useState("");
    const [showSearchBar, setShowSearchBar] = useState(true);
    const [currentTab, setCurrentTab] = useState("user");
    const pagenation = useRef({ pageNum: 0, loading: false });
    const [hasMore, setHasMore] = useState(true);
    async function loadMore() {
        if (pagenation.current.loading) {
            return;
        }
        const _currentTab = currentTab;
        pagenation.current.loading = true;
        let query: any = {
            phone,
            pageNum: pagenation.current.pageNum,
            pageSize: 10,
            type: _currentTab
        };
        getManagerUserList(query).then(({ data }) => {
            if (data.status === "error") {
                Toast.show(data.errorMessage);
                setHasMore(false);
            } else {
                if (currentTab === _currentTab) {
                    pagenation.current.pageNum++;
                    setUserList([...userList, ...data.userList]);
                    setHasMore(data.userList.length === 10);
                }
            }
        }).finally(() => {
            pagenation.current.loading = false;;
        })
    }
    useEffect(() => {
        pagenation.current.pageNum = 0;
        setUserList([]);
        setHasMore(true);
    }, [currentTab, phone]);
    const handleUserClick = (id: string) => {
        setScrollTop(userListRef.current?.scrollTop || 0);
        navigate(`/manager/user/info/${id}`)
    }
    useEffect(() => {
        if (location.pathname === "/manager/user") {
            if (userListRef) {
                setTimeout(() => {
                    userListRef.current.scrollTop = scrollTop
                }, 1)
            }
        }
    }, [location.pathname, scrollTop]);
    return (
        <div style={{ display: "flex", flexDirection: "column", height: "100vh" }}>
            <NavBar onBack={() => navigate(-1)} right={<SearchOutline onClick={() => setShowSearchBar(!showSearchBar)} />}>
                用户管理
            </NavBar>
            <div style={{ display: "flex", flexDirection: "column", flex: '1', background: "#f0f0f0", overflow: "auto" }}>
                <Tabs defaultActiveKey={currentTab} onChange={setCurrentTab}>
                    <Tabs.Tab title='用户列表' key='user' />
                    <Tabs.Tab title='星标用户' key='star' />
                </Tabs>
                {
                    showSearchBar && currentTab !== 'charge' && (
                        <SearchBar
                            style={{ margin: "2px 0" }}
                            onSearch={(data) => setPhone(data)}
                            placeholder='请输入用户手机号/用户名'
                            onClear={() => { phone && setPhone("") }}
                        />
                    )
                }
                <div style={{ flex: "1 1", overflowY: "auto", padding: "0 10px" }} ref={userListRef}>
                    {
                        userList && userList.map((user) => (
                            <div
                                key={user._id}
                                style={{ display: "flex", gap: "10px", padding: "10px", margin: '10px 0', borderRadius: "10px", background: "white" }}
                                onClick={() => handleUserClick(user._id)}>
                                <div style={{ display: "flex", alignItems: "center" }}>
                                    <Avatar src={user.avatar || defaultUser} />
                                </div>
                                <div style={{ flex: "1 1" }}>
                                    {user.remark || user.username} <br />
                                    <span style={{ color: "gray", fontSize: "13px" }}>{getTime(user.createTime)}</span>
                                </div>
                                <div>
                                    {user.balance?.toFixed(2)}
                                </div>
                            </div>
                        ))
                    }
                    <InfiniteScroll hasMore={hasMore} loadMore={loadMore} />
                    <SafeArea position='bottom' />
                </div>
            </div>
            <div style={{
                position: "fixed",
                bottom: 0,
                width: "100%",
            }}>
                <Footer />
            </div>
        </div>
    )
}